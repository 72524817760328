import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppUserState } from '../state/app-user-state/app-user.reducer';
import { logout } from '../state/app-user-state/app-user.actions';
import { getUserCookieData } from '../state/app-user-state/app-user.selectors';
import { DataService } from '../services/data.service';
import { AuthToken } from '../models/auth-token.model';
import { ConfigService } from '../services/config.service';
import { SessionService } from '../services/session.service';
import { NotifyService } from '../services/notify.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private refreshToken = '';
  private authToken = '';
  private apiHost = '';

  constructor(
    private appUserState: Store<AppUserState>,
    private dataService: DataService,
    private configService: ConfigService,
    private sessionService: SessionService,
    private notifyService:NotifyService
  ) {
    this.refreshTokenSubject.subscribe(
      (token) => {
        if (token) {
          localStorage.setItem('refreshToken', token);
        } else {
          localStorage.removeItem('refreshToken');
        }
      }
    );
    appUserState.pipe(select(getUserCookieData)).subscribe((userCookieData) => {
      if (userCookieData.refreshToken !== undefined) {
        this.refreshToken = userCookieData.refreshToken;
      }

      if ((userCookieData.token !== undefined) && (userCookieData.token.length > 2)) {
        this.authToken = userCookieData.token;
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthHeader(request)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          console.log('401 error');
          // Token läuft ab oder ist ungültig -> versuchen zu refreshen
          return this.handle401Error(request, next);
        } else {
          // Andere Fehler durchreichen
          return throwError(() => error);
        }
      })
    );
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    if ((!request.url.includes('api/login_check')) && (!request.url.includes('api/token/refresh'))) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authToken}`,
        },
      });

    }
    return request;
  }

  /**
   * Behandelt den 401-Fehler, versucht das Token zu erneuern und wiederholt den ursprünglichen Request.
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      if (this.refreshToken) {
        return this.dataService
          .makePostCall<AuthToken>(
            `${this.configService.config.apiUrl}${this.configService.config.authentication.tokeRefreshUrl}`,
            {refresh_token: this.refreshToken}
          )
          .pipe(
            switchMap((response: AuthToken) => {
              this.isRefreshing = false;
              this.refreshTokenSubject.next(response.token);
              this.sessionService.updateToken(response);

              // Wiederhole den ursprünglichen fehlgeschlagenen Request mit dem neuen Token
              return next.handle(this.addAuthHeader(request));
            }),
            catchError((error) => {
              console.error('Fehler beim Token-Refresh: ', error);
              this.isRefreshing = false;

              // Benutzer ausloggen, Fehler weitergeben
              this.appUserState.dispatch(logout());
              return throwError(() => error); // Fehler muss propagiert werden
            })
          );
      } else {
        console.warn('Kein gültiger Refresh-Token gefunden.');
        this.isRefreshing = false;

        // Benutzer ausloggen und Fehler manuell werfen
        this.appUserState.dispatch(logout());
        this.sessionService.logout();
        return throwError(() => new HttpErrorResponse({status: 401, statusText: 'Unauthorized'}));
      }
    } else {
      if(request.url.includes('api/token/refresh')){
        this.appUserState.dispatch(logout());
        this.notifyService.toast('error', 'Token refresh failed. Please try to login again.');
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => !!token), // Warte, bis der Token verfügbar ist
      take(1),
      switchMap(() => next.handle(this.addAuthHeader(request))) // Wiederhole den ursprünglichen Request
    );
  }

}
